<template>
  <div>
         <div class="search">
        <div class="title">满减列表</div>
        <hr />
      <el-form :inline="true"  class="top">
        <el-form-item label="标题:">
            <el-input v-model="params.title" ></el-input>
        </el-form-item>
        <el-form-item label="状态:">
              <el-select v-model="params.status" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
           
        </el-form-item>
            <!-- <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item> -->
         <el-form-item >
            <el-button type="primary"  @click="getList">查询</el-button>
            <el-button type="primary"   @click="reset">重置</el-button>
            <el-button type="primary"   @click="addBtn">添加</el-button>
        </el-form-item>
      
        </el-form>
     </div>
        <el-table
        :data="tableData"
        border
        style="width: 100%"
        align="center">
         <!-- <el-table-column type="selection" width="55"  align="center"> </el-table-column> -->
        <!-- <el-table-column type="index" width="50"   align="center"> </el-table-column> -->
        <el-table-column prop="fullId" width="100"   label="ID"   align="center"> </el-table-column>
        <el-table-column
        prop="title"
        label="标题"
        
        align="center">
        </el-table-column>
        <!-- <el-table-column
        label="头像 "
        width="180"
        align="center">
        <template slot-scope="scope">
            <el-image 
                style="width: 70px; height: 70px"
                :src="scope.row.headImg" 
                :preview-src-list="[scope.row.headImg]">
            </el-image>
        </template>
        </el-table-column> -->
         <el-table-column
        prop="label"
        label="标签"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="fullMinusFee"
        label="满减价格"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="addTime"
        label="活动时间"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="scope"
        label="使用范围"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="strStatus"
        label="状态"
        width="200"
        align="center">
        </el-table-column>
        <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
         
            <el-button type="primary"  size="mini" @click="kill(scope.row,1)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary"  size="mini" @click="kill(scope.row,2)">使失效</el-button>
           
        </template>
        </el-table-column>
    </el-table>
         <div class="block">
         <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
        <div>
              <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.currentPage"
            :page-sizes="[5,10,20]"
            :page-size="params.pageSize"
            layout="total,sizes,prev,pager,next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
     </div>
  </div>
</template>

<script>
import { deceList,deceListInvalid } from '../../../api/fullDecrement.js'
export default {
    data() {
        return {
            total: 0,
            params: {
                currentPage: 1,
                pageSize: 5,
                title: '',
                status: 0,
                // beginTime: '',
                // endTime: '',
            },
            tableData: [],
            date: null,
            //  0 全部
            //     1 进行中
            //     2 未开始
            //     3 已结束
            //     4 已失效
            options:[
               
                {
                    label:'全部',
                    value:0
                },
                {
                    label:'进行中',
                    value:1
                },
                {
                    label:'未开始',
                    value:2
                },
                {
                    label:'已结束',
                    value:3
                },
                {
                    label:'已失效',
                    value:4
                },
            ],
            pickerOptions0: {
            disabledDate(time) {
                return time.getTime() > Date.now() - 8.64e6
            },
         }
        }
    },
    created(){
        this.getList()
    },
    methods: {
        async getList(){
            // this.params.beginTime = this.date ?  this.date[0] : ''
            // this.params.endTime = this.date ? this.date[1] : ''
            const {data} = await deceList(this.params)
            if(data.code == 0){
                this.tableData = data.list
                this.total = data.pagination.total
            }else{
                this.message.warning(data.msg)
            }
        },
          handleSizeChange(e){
            this.params.pageSize = e
            this.getList()
        },
          // 页数改变
        handleCurrentChange(e){
            this.params.currentPage = e
            this.getList()
        },
        reset(){
           
            this.params.currentPage = 1
            this.params.pageSize = 5
            this.params.status = 0
            this.params.title = ''
            // this.params.beginTime = ''
            // this.params.endTime = ''
            // this.date = null
             this.getList()
        },
        addBtn(){
           this.$router.push(`/addFullDecrement/0`)
        },
        edit(row){
            this.$router.push(`/addFullDecrement/${row.fullId}`)
        },
        kill(row,type){
            let str 
            let status 
            if(type == 1){
                str = '删除'
                status = 0
            }else if(type == 2){
                str = '使其失效'
                 status = 4
            }
             this.$confirm(`此操作将${str}, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async () => {
                  const { data } = await deceListInvalid({
                        fullId: row.fullId,
                        status
                    })
                    if(data.code == 0){
                        this.getList()
                         this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                    }else{
                        this.$message({
                            type: "warning",
                            message: data.msg
                        });
                    }
           
            }).catch(() => {
                
            });
        },
    }
}
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top{
    margin-top: 20px;
}
.block{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
</style>